import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import Bandcamp from './bandcamp';
import Anchor from './anchor';
import { setBackgroundStyle } from '../lib/util';

export default props => {
    const data = useStaticQuery(
      graphql`
        query {
          allImageSharp {
            edges {
              node {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_withWebp
                  originalName
                }
              }
            }
          }
        }
      `
    );
     
    const title = `${props.artist} - ${props.title}`;
    const image = data.allImageSharp.edges.find( edge => {
      return edge.node.fluid.originalName === `${props.id}.webp`;
    });

    return (
        <>
            <Bandcamp
                {...props}
                style={setBackgroundStyle(image)}
            />

            <h1>
              <Anchor
                href={`https://nutriot.bandcamp.com/${props.bandcamp.slug}`}
                title={title}
              >
                {title}
              </Anchor>
            </h1>

            <p>
                <Anchor
                    href={`https://nutriot.bandcamp.com/${props.bandcamp.slug}`}
                    title={`Buy ${title}`}
                >
                    Buy {props.formats.join(' or ')} release
                </Anchor>
            </p>

            { props.vendors.length && <p>Also available from:</p> }

            <ul className="unstyled">
            {
                props.vendors.length && props.vendors.map(vendor => {
                    return (
                        <li key={vendor.name}>
                            <Anchor
                                external={true}
                                href={vendor.link}
                                title={`Buy ${title} from ${vendor.name}`}
                            >
                                {vendor.name}
                            </Anchor>
                        </li>
                    )
                })
            }
            </ul>
        </>
    )
};