import React from 'react';

import Embed from './embed';

export default props => {
    
    const src = `https://bandcamp.com/EmbeddedPlayer/album=${props.bandcamp.id}/size=large/bgcol=333333/linkcol=ffffff/minimal=true/transparent=true/`
    
    return (
        <Embed
            frameBorder="0"
            loading="lazy"
            scrolling="no"
            seamless={true}
            src={src}
            style={props.style}
            title={props.title}
        />
    )
};