import React, { useEffect, useState } from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Release from '../components/release'

const IndexPage = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    (async() => {

      let { releases } = await import('../data/releases');

      setData(releases.slice().sort().reverse());
    })();
  }, []);

  return (
    <Layout>
      <SEO title="Releases" description="Browse all records released on Nutriot Recordings" />
      {
        data.length && data.map(item => {
          return (
            <section
              key={item.id}
              className="item"
            >
              <Release {...item} />
            </section>

          )
        })
      }
    </Layout>
  );
};

export default IndexPage
